import { useEffect, useState } from "react";

const useQuery = () => {
  const [query, setQuery] = useState(new URLSearchParams(""));
  useEffect(() => {
    setQuery(new URLSearchParams(window?.location?.search));
  }, [window?.location]);

  return { query };
};

export default useQuery;
