import React from "react";
import { Div } from "atomize";
import { Button, Image, Text } from "@chakra-ui/react";
import { useNavigate } from "@reach/router";
import Logo from "../../../images/logo.png";
import "./screens.css";
import { navigate } from "gatsby";

const NothingToShow = () => {
  const redirectToDashboard = () => {
    navigate("/dash");
  };

  return (
    <>
      <Div
        d="flex"
        align="center"
        justify="center"
        textAlign="center"
        h="100vh"
      >
        <Div className="wrapperAlert">
          <Div>
            <Div className="topHalf">
              <Div align="center" d="flex" justify="center" flexDir="column">
                <Image src={Logo} alt="File here" w="150px" mb={5} />
              </Div>

              <Text my={2} color="black" fontWeight="bold">
                No hay nada que mostrar :/
              </Text>
            </Div>

            <Div>
              <Button colorScheme="blue" onClick={redirectToDashboard}>
                Ir al dashboard
              </Button>
            </Div>
          </Div>
        </Div>
      </Div>
    </>
  );
};
export default NothingToShow;
