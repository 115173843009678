import React, { useEffect, useRef } from "react";
import SEO from "../components/seo";
import SuccessPayment from "../components/shared/payments/success";
import Layout from "../components/layout";
import { useQuery as useReactQuery } from "react-query";
import { client } from "../lib/client";
import useQuery from "../hooks/useQuery";
import NothingToShow from "../components/shared/payments/NothingToShow";
// import GetStartedBanner from "../components/homepage/GetStartedBanner"

const IndexPage = ({ paymentId }) => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  const { data, isLoading } = useReactQuery({
    queryKey: ["get-payment-details-public", paymentId],
    queryFn: async ({ queryKey }) => {
      return (await client.get(`/public/payment/${queryKey[1]}`)).data?.data;
    },
    enabled: true,
    retryDelay: 10000,
  });

  if (isLoading) {
    return <NothingToShow />;
  }

  return (
    <>
      <SEO title="FileHire" description="Payment success" />

      <SuccessPayment data={data} paymentId={paymentId} />
    </>
  );
};

const Wrapper = () => {
  const { query } = useQuery();
  const paymentId = query.get("id");

  if (!paymentId) {
    return null;
  }
  return (
    <>
      <IndexPage paymentId={paymentId} />
    </>
  );
};

const Page = () => {
  return (
    <Layout>
      <Wrapper />
    </Layout>
  );
};

export default Page;
