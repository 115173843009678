import React from "react";
import { Div, Text } from "atomize";
import { Button, Image } from "@chakra-ui/react";

import Logo from "../../../images/logo.png";
import "./screens.css";
import NothingToShow from "./NothingToShow";
import { Link } from "gatsby";

const SuccessPayment = ({ data, paymentId }) => {
  if (!data) {
    return <NothingToShow />;
  }

  return (
    <>
      <Div
        d="flex"
        align="center"
        justify="center"
        textAlign="center"
        h="100vh"
      >
        <Div className="wrapperAlert">
          <Div>
            <Div className="topHalf">
              <Div align="center" d="flex" justify="center" flexDir="column">
                <Image src={Logo} alt="File here" w="150px" mb={5} />
                <svg viewBox="0 0 512 512" width="100" title="check-circle">
                  <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" />
                </svg>
              </Div>

              <Text textSize="display2">Tu pago ha sido exitoso</Text>
            </Div>

            <Div>
              {/*   <Text textSize="title" m={{ y: "1rem" }}>
                Transaction Number: xxxx-xxx-xx
              </Text>
              <Text textSize="body" m={{ y: "1rem" }}>
                Amount Paid: $25
              </Text>
*/}
              <Link to={`/dash/events/${paymentId}`}>
                <Button colorScheme="blue">Ver progreso</Button>
              </Link>
            </Div>
          </Div>
        </Div>
      </Div>
    </>
  );
};
export default SuccessPayment;
